<template>
  <div>
    <change-detail :detail="changeDetail"></change-detail>

    <Row class="p-t-10">
      <i-col span="24">
        <i-button type="info" @click="handlePreviousStep">上一步</i-button>
        <i-button class="right" type="success" @click="handleNextStep">提交</i-button>
      </i-col>

    </Row>
    <Modal v-model="showChoiceSubModal">
      <p slot="header">
        <span>提交合同审批</span>
      </p>
      <div class="p-l-10 p-r-10">
        <p class="p-t-10">请选择审批通道：</p>
        <RadioGroup v-model="sub">
          <Radio
            v-for="(item, i) in subs"
            :key="i"
            :label="item.subProcessType"
            >{{ item.subProcessTypeName }}</Radio
          >
        </RadioGroup>
      </div>
      <div slot="footer">
        <i-button
          type="primary"
          @click="handleSubmit"
        :loading="loadingSubmit"
          >确认</i-button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import changeDetail from '@/components/contractChange/ChangeDetail'
import { getSubProcessList } from '@/api/approval/processconfig'

import { changeContract, getVersionDetail } from '@/api/scp/contractchange'
import { getEarlierDate, getLaterDate } from '@/utils'
import { ParseDate } from '@/utils/dateFormat'
import { getChangeFields } from '@/utils/contractChange'

export default {
  props: {
    params: Object,
    isPlatform: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      contract: {},
      changeDetail: [],
      showChoiceSubModal: false,
      subs: [],
      sub: null,
      loadingSubmit: false
    }
  },
  methods: {
    handlePreviousStep () {
      this.$emit('prev-step')
    },

    initSubProcessList () {
      getSubProcessList({ processType: 8 }).then((data) => {
        if (data && data.length > 0) {
          this.subs = data
          this.sub = this.subs.length ? this.subs[0].subProcessType : ''
        } else {
          this.subs = []
        }
      })
    },
    handleNextStep () {
      this.initSubProcessList()
      this.showChoiceSubModal = true
    },
    formatDate (date) {
      return ParseDate(date)
    },
    handleSubmit () {
      this.loadingSubmit = true
      const contract = this.contract.submitParams
      contract.isSubmitDraft = true

      contract.subProcessType = this.sub
      changeContract(contract).then(res => {
        if (res && !res.errcode) {
          this.$Notice.success({ desc: '提交成功！' })
          this.$emit('on-submit')
        }
        this.loadingSubmit = false
      })
    }
  },
  components: {
    changeDetail
  },
  created () {
    this.contract = this.params
    getVersionDetail({ versionId: this.params.newVersionId }).then(res => {
      this.changeDetail = getChangeFields(res.changeDetail)
    })
  },
  computed: {
    startDate: function () {
      return getEarlierDate(this.contract.orders.map(item => item.startDate))
    },
    endDate: function () {
      return getLaterDate(this.contract.orders.map(order => order.endDate))
    }
  }

}
</script>
